import { type AuthorizeCreditCard } from "@paparazzi/types/paparazzi.authorizecim.views";
import { createStore } from "@virgodev/vue-models/utils/create_store";

export interface AuthorizeCreditCardWithId extends AuthorizeCreditCard {
  id: number;
  cvc?: string;
}

export const useCardsStore =
  createStore<AuthorizeCreditCardWithId>("authorize");
// export const useCardsStore = defineStore("cards", () => {
//   const cards = useDefaultCardStore();
//   cards.list;

//   return {
//     ...cards,
//   };
// });

<template>
  <div :class="['header', { 'header-scrolled': isScrolled }]">
    <Sidebar v-model:visible="open">
      <div class="p-menu flex-column">
        <div v-for="item of mobileMenuItems" class="p-menuitem">
          <div class="p-menuitem-content">
            <RouterLink
              :to="item.url"
              class="p-menuitem-link"
              @click="open = false"
            >
              {{ item.label }}
            </RouterLink>
          </div>
        </div>
      </div>
      <!-- <Menu :model="mobileMenuItems" /> -->
    </Sidebar>
    <div class="wrap">
      <RouterLink class="artwork" :to="{ name: 'home' }">
        <img src="@/assets/img/artwork.svg" />
      </RouterLink>

      <div v-if="user.isAuthenticated" class="welcome-text">
        Welcome, {{ user.isRep ? user.props.username : user.props.name }}
      </div>

      <div class="small-menu">
        <i class="pi pi-bars" @click="open = !open" />
      </div>
      <div class="group">
        <MenuItem v-for="item of menuItems" :to="item.url">{{
          item.label
        }}</MenuItem>
      </div>
      <div class="icons">
        <!-- <span>{{ user.props.username }}</span> -->
        <ShopStatus v-if="user.props.id" />
        <router-link
          v-if="user.isAuthenticated"
          :to="{ name: 'accounts-profile' }"
          class="profile"
          aria-label="Go to profile"
        >
          <VectorImage name="profile" class="signed-in" />
        </router-link>
        <a
          v-else
          href="#"
          @click.prevent="doSignin"
          class="profile"
          aria-label="Go to profile"
        >
          <VectorImage name="profile" />
        </a>
        <SigninModal v-model:visible="showSigninModal" />
        <span class="header-search">
          <span class="flex-row">
            <VectorImage
              @click.prevent="toggleSearch"
              name="search"
              class="search-icon"
              aria-label="Search Shop"
            />
          </span>
          <form
            id="searchPopover"
            ref="searchPopover"
            :class="{ open: searchOpen }"
            @submit.prevent="handleSubmit"
          >
            <InputText
              v-model="searchText"
              placeholder="Search"
              type="search"
            />
          </form>
        </span>
        <router-link
          :to="{ name: 'shop-wishlist' }"
          class="wishlist"
          :class="{ 'pa-pink': wishlist.count > 0 }"
        >
          <VectorImage
            name="wishlist"
            :fill="wishlist.count > 0 ? '#EC008C' : 'none'"
          />
          <Badge
            v-if="wishlist.count > 0"
            :value="wishlist.count"
            :class="{
              one: wishlist.count > 0 && wishlist.count < 10,
              ten: wishlist.count >= 10 && wishlist.count < 100,
              hundo: wishlist.count >= 100 && wishlist.count < 1000,
              thous: wishlist.count >= 1000,
            }"
            class="wishlist-count"
          />
        </router-link>
        <router-link
          :to="{ name: 'shop-cart' }"
          class="bag"
          aria-label="Shopping Bag"
        >
          <VectorImage name="bag" />
          <Badge
            v-if="cart.count > 0"
            :value="cart.count"
            :class="{
              one: cart.count > 0 && cart.count < 10,
              ten: cart.count >= 10 && cart.count < 100,
              hundo: cart.count >= 100 && cart.count < 1000,
              thous: cart.count >= 1000,
            }"
            class="cart-count"
          />
          <img
            v-if="firebase.mirrors.find((m) => m.name === 'frenzy')?.data"
            class="frenzy"
            src="@/assets/img/frenzy.gif"
          />
        </router-link>
      </div>
    </div>
    <div v-if="searchOpen" class="mobile-search-panel">
      <InputText v-model="searchText" placeholder="Search" type="search" />
    </div>
  </div>
</template>

<script setup lang="ts">
import MenuItem from "@/components/MenuItem.vue";
import VectorImage from "@/components/VectorImage.vue";
import { useCartStore } from "@/stores/cart";
import { useFirebaseStore } from "@/stores/firebase";
import { useTimesyncStore } from "@/stores/timesync";
import { useUserStore } from "@/stores/user";
import { useWishlistStore } from "@/stores/wishlist";
import throttle from "lodash/throttle";
import Badge from "primevue/badge";
import InputText from "primevue/inputtext";
import Sidebar from "primevue/sidebar";
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import {
  RouterLink,
  useRoute,
  useRouter,
  type RouteLocationAsRelativeGeneric,
} from "vue-router";
import SigninModal from "./profile/SigninModal.vue";
import ShopStatus from "./shop/ShopStatus.vue";

const user = useUserStore();
const cart = useCartStore();
const route = useRoute();
const router = useRouter();
const firebase = useFirebaseStore();
const timesync = useTimesyncStore();
const wishlist = useWishlistStore();

const open = ref(false);
const searchText = ref(route.query.search || "");
const searchOpen = ref(false);
const searchPopover = ref();
const showSigninModal = ref(false);

const menuItems = computed(() => {
  return [
    { label: "Shop", url: { name: "shop" } },
    { label: "Join", url: { name: "opportunities" } },
    { label: "Promotions", url: { name: "promotions" } },
    { label: "Fashion Fix", url: { name: "fashion-fix" } },
    // { label: "Events", url: { name: "events" } },
    { label: "Pulse", url: { name: "pulse" } },
  ];
});
const mobileMenuItems = computed(() => {
  const retval: { label: string; url: RouteLocationAsRelativeGeneric }[] = [];

  return retval.concat(menuItems.value);
});

watch(
  () => route.name,
  () => (showSigninModal.value = false),
);
watch(
  () => route.query?.search,
  () => (searchText.value = route.query?.search || ""),
);

watch(searchText, (nv, ov) => {
  if (searchText.value) {
    router.replace({ name: "shop", query: { search: searchText.value } });
  } else if (router.currentRoute.value.name === "shop") {
    router.replace({
      name: "shop",
    });
  }
});

watch(route, () => {
  if (route.name !== "shop") {
    searchOpen.value = false;
    searchText.value = "";
  }
});

function handleSubmit() {
  if (route.name !== "shop") {
    router.push({ name: "shop" });
  }
}

async function toggleSearch() {
  searchOpen.value = !searchOpen.value;
  if (searchOpen.value) {
    const el = searchPopover.value.querySelector("input");
    await nextTick();
    if (el) {
      el.select();
      el.focus();
    }
  }
}

function doSignin() {
  if (route.name !== "accounts-signin") {
    showSigninModal.value = true;
  }
}

const isScrolled = ref(false);

function handleScroll() {
  const scrollPosition = window.scrollY || document.documentElement.scrollTop;
  if (isScrolled.value && scrollPosition < 40) {
    isScrolled.value = false;
  } else if (!isScrolled.value && scrollPosition > 183) {
    isScrolled.value = true;
  }
}
// Throttle the scroll handler so it can't jiggle
const throttledScroll = throttle(handleScroll, 183);

onMounted(() => {
  window.addEventListener("scroll", throttledScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener("scroll", throttledScroll);
});
</script>

<style scoped>
.header {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 100;
  background-color: var(--White, #fff);
  transition: all 0.3s ease;
}
.header .wrap {
  display: grid;
  grid-template-areas:
    "blank header welcome"
    "blank menu icons";
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto auto;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 24px;
  padding: 56px;
  padding-bottom: calc(var(--1XL) / 2);
  margin: auto;
  max-width: 1232px;
  transition: all 0.3s ease;
}

.header-scrolled .wrap {
  padding: 16px 56px;
  background-color: var(--White, #fff);
  row-gap: 0;
  transition: all 0.3s ease;
}

.header-scrolled .wrap .artwork {
  display: none; /* Hide the logo */
  transition: all 0.3s ease;
}

.header-scrolled .wrap .welcome-text {
  display: none; /* Hide the welcome text */
  transition: all 0.3s ease;
}

.artwork {
  place-self: center;
  height: 33.96px;
  grid-area: header;
  flex: 1 1 auto;
  min-width: 100px;
  transition: all 0.3s ease;
}
.artwork img {
  width: 100%;
  transition: all 0.3s ease;
}

.welcome-text {
  display: flex;
  grid-area: welcome;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  font-size: 14px;
  position: relative;
  top: 15px;
  transition: all 0.3s ease;
}

.small-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-area: menu;
  display: none;
  transition: all 0.3s ease;
}

.group {
  gap: 24px;
  grid-area: menu;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  grid-area: icons;
  gap: 8px;
  vertical-align: middle;
  transition: all 0.3s ease;
}

.signed-in {
  color: #ec008c;
}

.img,
.pi {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 30px;
}

.search-icon {
  cursor: pointer;
}

.icons a svg {
  transition: 0.2s ease;
}

.icons a svg:hover {
  stroke-width: 1.5;
}

.bag {
  display: flex;
  flex-direction: row;
  position: relative;
}
.p-badge {
  position: absolute;
  right: 0;
  margin-right: -10px;
  z-index: 2;
}
.bag .frenzy {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  translate: 8px -15px;
  width: 48px;
  height: 60px;
  /* opacity: 0.4; */
  object-fit: cover;
  touch-action: none;
  pointer-events: none;
}

.pi-bars:before {
  font-size: 21px;
  font-weight: 100;
  stroke-width: 0;
}

.header-search {
  position: relative;
}

#searchPopover.open {
  display: block;
}
#searchPopover::before {
  display: none;
}
#searchPopover::after {
  display: none;
}
#searchPopover {
  display: none;
  z-index: 10;
  padding: 12px;
  translate: -371px -9px;
  background: white;
  position: absolute;
  overflow: visible;
  padding: 12px;
  top: 34px;
  .p-inputtext {
    padding: 12px;
    min-width: 385px;
    border: 1px solid var(--Black, #000);
  }
}
.mobile-search-panel {
  display: none;
  grid-column: 1/-1;
  grid-row: 3/3;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

@media (max-width: 1199px) and (min-width: 801px) {
  .welcome-text {
    /* top: -10px; */
  }
  .header .wrap {
    padding: 56px 40px 32px;
  }
  .header-scrolled .wrap {
    padding: 16px 56px;
  }
}

@media (max-width: 800px) {
  #app #searchPopover {
    display: none;
  }
  .mobile-search-panel {
    display: block;
  }
  .mobile-search-panel {
    margin-top: 10px;
    .p-inputtext {
      width: 97%;
      border: 1px solid var(--Black, #000);
      padding: 12px;
    }
  }

  .welcome-text {
    display: none;
  }
}

#app .search-badge {
  min-width: 12px;
  height: 12px;
  display: none;
}

.wishlist {
  display: flex;
  flex-direction: row;
  position: relative;
}

.search-badge,
.cart-count,
.wishlist-count {
  border-radius: 50%;
  display: flex;
  aspect-ratio: 1/1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}
.cart-count.one,
.wishlist-count.one {
  font-size: 8px;
}
.cart-count.ten,
.wishlist-count.ten {
  font-size: 8px;
}
.cart-count.hundo,
.wishlist-count.hundo {
  font-size: 8px;
}
.cart-count.thous,
.wishlist-count.thous {
  font-size: 8px;
}

@media (max-width: 1199px) and (min-width: 801px) {
  .header {
    gap: 0;
  }
}

@media (max-width: 1014px) and (min-width: 921px) {
  .menu-item-default {
    padding: 10px;
    margin: 0 5px;
  }
}

@media (max-width: 920px) and (min-width: 801px) {
  .menu-item-default {
    padding: 10px 6px;
    margin: 0 5px;
  }
  .icons {
    margin-left: 30px;
  }
}

@media (max-width: 800px) {
  .header .wrap {
    margin: 0;
    margin-top: var(--XS);
    padding: var(--2XS, 8px) var(--2XS, 8px);
    gap: 0;
    grid-template-areas:
      "header header"
      "menu icons";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    align-items: flex-start;
    transition: all 0.3s ease;
  }

  .artwork {
    height: auto;
  }
  .artwork img {
    max-height: 39px;
    padding: var(--2XS, 8px) var(--XS, 16px);
  }
  .small-menu {
    display: flex;
    gap: var(--2XS, 8px);
    padding: 6px 0;
  }
  .group {
    display: none;
  }
  .icons {
    padding: 6px 0;
  }
}

@media (min-width: 801px) and (max-width: 1400px) {
  .icon-menu {
    right: 0px;
  }
}
</style>

import debug from "debug";
import lz from "lz4js";
import msgpack from "msgpack-lite";

const log = debug("websocket");

let socket = null;

function connect(endpoint) {
  if (socket) {
    try {
      socket.close();
      socket = null;
    } catch (ex) {
      console.log(ex);
    }
  }
  try {
    socket = new WebSocket(endpoint);
  } catch (ex) {
    console.error("Failed to connect", ex);
  }
}

function emit(event, data = {}) {
  if (socket?.readyState == WebSocket.OPEN) {
    // if (data.msgpack) {
    socket.send(msgpack.encode([event, data]));
    // socket.send(lz.compress(msgpack.encode([event, data])));
    // } else {
    //   socket.send("4" + JSON.stringify([event, data]));
    // }
  }
}

function heartbeat() {
  log("♡");
  emit("heartbeat", { msgpack: true });
}

function close() {
  if (socket) {
    socket.close();
  }
}

export function createDisconnectedError(msg) {
  let err = new Error(msg);
  err.code = "disconnected";
  return err;
}

export class SocketStatus {
  constructor(socket, error = null) {
    this.socket = socket;
    this._error = error;
  }

  get ok() {
    return !this._error && this.socket.connected;
  }

  get error() {
    return this._error;
  }

  toString() {
    return "" + (this._error ? this.error : this.socket.connected);
  }
}

export default class Socket {
  constructor(endpoint) {
    this.ready = new Promise((r) => {
      this._ready = r;
    });

    if (!endpoint) {
      endpoint = location.host;
      let proto = /https/i.test(location.protocol) ? "wss" : "ws";
      endpoint = `${proto}://${endpoint}/socket.io/`;
    }

    endpoint = endpoint.replace("https:", "wss:");
    endpoint = endpoint.replace("http:", "ws:");

    this.endpoint = endpoint;
    this.pending = [];
    this.connectListeners = [];
    this.disconnectListeners = [];
    this.counts = {};
    this.events = {};
    this.makePromise();
    this.connect();
    this.connected = false;
    this.baseTimeout = 1000;
    this.heartbeatTimeout = 30 * 1000;
    this.timeout = this.baseTimeout;
    this.closed = false;

    if (typeof window !== "undefined") {
      window.addEventListener("online", () => this.connect());
      window.addEventListener("offline", () => this.close());
    }
  }

  async createWorker() {
    log("creating worker");
    if (!socket) {
      // dither connect time to prevent the thundering herd
      await new Promise((r) => setTimeout(r, Math.random() * 1500));

      connect(this.endpoint);
      this._ready();
    }

    if (socket) {
      socket.onmessage = (e) => {
        // log("msg", e.data);
        this.onMessage(e.data);
      };
      socket.onopen = (e) => {
        log("connect");
        this.onConnect(e);
      };
      socket.onclose = (e) => {
        log("disconnected");
        this.onDisconnect(e);
      };
      // Call onopen directly if socket is already open
      if (socket.readyState == WebSocket.OPEN) {
        socket.onopen();
      }
    }
  }

  async wait(timeout = 10000) {
    if (this.connected) {
      return new SocketStatus(this);
    }

    let connector = null;
    let connectListener = new Promise((resolve) => {
      connector = this.connectListeners.push(resolve) - 1;
    });

    let result = await Promise.race([
      new Promise((r) =>
        setTimeout(
          () => r(new SocketStatus(this, new Error(`Timeout (${timeout})`))),
          timeout,
        ),
      ),
      connectListener,
    ]);

    if (connector !== null) {
      this.connectListeners.splice(connector);
    }

    return result;
  }

  isConnected(timeout = 3000) {
    let rejector = null;
    let disconnectListener = new Promise((r, reject) => {
      rejector = this.disconnectListeners.push(reject) - 1;
    });
    let result = Promise.race([
      new Promise((r) =>
        setTimeout(
          () =>
            r(
              new SocketStatus(
                this,
                new Error(`Connected Timeout (${timeout})`),
              ),
            ),
          timeout,
        ),
      ),
      disconnectListener,
    ]);

    if (rejector !== null) {
      this.disconnectListeners.splice(rejector, 1);
    }

    return result;
  }

  count(event) {
    this.counts[event] = (this.counts[event] || 0) + 1;
  }

  makePromise() {
    this.promise = new Promise((resolve) => {
      this._resolve = resolve;
    });
  }

  async connect() {
    log("waiting for worker");

    this.ready = new Promise((r) => {
      this._ready = r;
    });

    if (!socket) {
      await this.createWorker();
    }
    await this.ready;
    // if socket was disconnected, it is no longer closed
    this.closed = false;
    log(`connecting to ${this.endpoint}`);

    this.connectedp = new Promise((resolve) => {
      this._connected = () => {
        let status = new SocketStatus(this);
        resolve(status);
        for (let connector of this.connectListeners) {
          connector(status);
        }
        this.connectListeners = [];
      };
      this._connectedFailed = (e) => {
        resolve(new SocketStatus(this, e));
        for (let disconnector of this.disconnectListeners) {
          disconnector(e);
        }
        this.disconnectListeners = [];
      };
    });
  }

  onConnect(e) {
    log("connected", e);

    // reset timeout
    this.timeout = this.baseTimeout;

    this.connected = true;
    this._connected();
    this._resolve(true);
    this.callEventListeners("connect", null);

    // pending messages
    for (let msg of this.pending) {
      log(`resending pending message ${msg[0]}`);
      this.emit(msg[0], msg[1]);
    }
    this.pending = [];

    this.count("connect");
  }

  async onMessage(packet) {
    this.heartbeat();

    let decoded = null;
    if (packet instanceof Blob) {
      try {
        decoded = new Uint8Array(await new Response(packet).arrayBuffer());
        decoded = lz.decompress(decoded);
        decoded = msgpack.decode(decoded);
      } catch (ex) {
        decoded = null;
      }
    }

    if (decoded) {
      const [event, data] = decoded;
      this.callEventListeners("_message_", decoded);
      this.callEventListeners(event, data);
      this.count(event);
      decoded = null;
    } else {
      this.onOldMessage(packet);
    }
    this.count("_message");
  }

  onOldMessage(packet) {
    let bit = packet[0];
    if (bit == "0") {
      log("got connect confirmation");
    } else if (bit == "4") {
      let bit2 = packet[1];
      if (bit2 == "2") {
        let data = JSON.parse(packet.slice(2));
        this.callEventListeners("_message_", data);
        this.callEventListeners(data[0], data[1]);

        this.count(data[0]);
      } else {
        log("don't know what to do with this");
      }
    }
    this.count("_message");
  }

  getDisconnectReason(code) {
    return {
      1000: "Normal Closure",
      1001: "Going Away",
      1002: "Protocol error",
      1003: "Unsupported Data",
      1004: "Reserved",
      1005: "No Status Rcvd",
      1006: "Abnormal Closure",
      1007: "Invalid frame payload data",
      1008: "Policy Violation",
      1009: "Message Too Big",
      1010: "Mandatory Ext",
      1011: "Internal Server Error",
      1015: "TLS handshake",
    }[code];
  }

  onDisconnect(e) {
    let reason = this.getDisconnectReason(e.code);
    if (e.reason) {
      log(`disconnected (${reason} - ${e.reason})`);
    } else {
      log(`disconnected (${reason})`);
    }
    this._connectedFailed(createDisconnectedError(`Disconnected (${reason})`));

    this.connected = false;
    this.callEventListeners("disconnect", e);

    // reset heartbeat
    clearTimeout(this.__heartbeat);

    // attempt to reconnect
    clearTimeout(this.__reconnect);

    if (!this.closed) {
      if (this.timeout >= this.baseTimeout) {
        this._resolve(false);
        this.makePromise();
      }

      this.__reconnect = setTimeout(() => this.connect(), this.timeout);
      log(`Will attempt to reconnect in ${this.timeout}ms`);

      // dither reconnect time to prevent the thundering herd
      this.timeout += Math.floor(Math.random() * 15000) + this.timeout;
      this.timeout = Math.min(60 * 1000, this.timeout);
    } else {
      this._resolve(false);
    }

    this.count("disconnect");
    socket = null;
  }

  addEventListener(event, func) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(func);
  }

  removeEventListener(event, func) {
    if (this.events[event]) {
      let idx = this.events[event].indexOf(func);
      if (idx > -1) {
        this.events[event].splice(idx, 1);
      }
    }
  }

  callEventListeners(event, data) {
    if (this.events[event]) {
      for (let func of this.events[event]) {
        func(data);
      }
    }
  }

  on(event, callback) {
    this.addEventListener(event, callback);
  }

  once(event, callback, msgid) {
    let listener = (data) => {
      let run = false;
      if (data && data.msgid) {
        if (data.msgid === msgid) {
          run = true;
        }
      } else {
        run = true;
      }
      if (run) {
        this.removeEventListener(event, listener);
        callback(data);
      }
    };
    this.addEventListener(event, listener);
  }

  off(event, callback) {
    if (callback) {
      this.removeEventListener(event, callback);
    } else {
      if (this.events[event]) {
        delete this.events[event];
      }
    }
  }

  emit(event, data = {}, retry = true) {
    if (this.connected) {
      emit(event, data);
      this.heartbeat();
      this.count("_emit");
    } else if (retry) {
      log(`Queuing message to send later: ${event}`);
      this.pending.push([event, data]);
      this.pending = this.pending.slice(0, 3);
    }
  }

  heartbeat() {
    if (this.heartbeatTimeout > 0) {
      clearTimeout(this.__heartbeat);
      this.__heartbeat = setTimeout(() => {
        log("\u2764");
        heartbeat();
        this.count("_heartbeat");
      }, this.heartbeatTimeout);
    }
  }

  close() {
    this.closed = true;
    close();
  }

  sever() {
    close();
  }
}

import { useShopStore } from "../shop";
import type { Cart } from "../shop_defs";

export type SerializedCartItem = [string, number, number | undefined, any];

export function serializeItems(cart: Cart) {
  const shop = useShopStore();

  let items: SerializedCartItem[] = [];
  for (let item of cart.items) {
    let promo = item.promotion
      ? shop.promos.find((p) => p.id == item.promotion)
      : null;
    if (
      !item.promotion ||
      item.extras ||
      (promo && promo.promo_type == "local_userchoice")
    ) {
      items.push([
        item.product?.remote_id,
        item.quantity,
        item.promotion,
        item.extras,
      ]);
    }
  }
  return items;
}

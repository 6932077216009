import { uapi } from "@/utils/api";
import { getCeleryResult } from "@/utils/bg";
import { APP_TYPE } from "@/utils/settings";
import type { ApiError, ApiSuccess } from "@virgodev/bazaar/functions/api";
import { nextTick } from "vue";
import { useCartStore } from "../cart";
import { useCheckoutStore } from "../checkout";
import { useUserStore } from "../user";
import { serializeItems } from "./serialize_cart";

export async function getOfficialTotals() {
  const user = useUserStore();
  const cart = useCartStore();
  const checkout = useCheckoutStore();

  // clear current errors and prevent redundant updates
  let sync_id = new Date().toISOString();
  // TODO: cart.object.checked = true
  // TODO: cart.object.changed = sync_id
  cart.object.errors = null;
  // TODO: store.commit("cartStatus", "request");

  let syncData = {
    shipping_address: cart.object.shipping_address
      ? cart.object.shipping_address.id
      : null,
    billing_address: cart.object.billing_address
      ? cart.object.billing_address.id
      : null,
    shipping_choice: cart.object.shipping_choice
      ? cart.object.shipping_choice
      : null,
    use_credits: checkout.useCredits,
    use_giftcards: false,
    items: serializeItems(cart.object),
    // TODO: coupon_code: store.getters.cartCouponCode,
  };

  const packet = {
    sync_id: new Date(sync_id).getTime(),
    cart: cart.object.id,
    check: true,
    data: syncData,
    debug: {
      source: APP_TYPE,
      user: user.props.id,
    },
  };
  let response: ApiSuccess | ApiError | null = await uapi({
    url: "cart/deferred",
    json: packet,
    method: "POST",
  });
  if (response && response.ok) {
    try {
      const result = await getCeleryResult({ task: response.body.task });
      if (result.error) {
        console.error("getOfficialTotals error", result);
      } else {
        cart.object.changed = result.changed;
        await nextTick();
        cart.object.totals = result.totals;
        cart.object.tax = result.tax;
        cart.object.total_volume = result.total_volume;
      }
      return result;
    } catch (ex) {}
  }
  return null;
}

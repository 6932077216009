import {
  createRouter as _createRouter,
  createMemoryHistory,
  createWebHistory,
} from "vue-router";

import { useUserStore } from "@/stores/user";
import { useSiteLockStore } from "@/utils/site_lock";
import debug from "debug";
import type { Router } from "vue-router";
import { routes } from "vue-router/auto-routes";

const ROOT = "/";
const log = debug("routes");

export function createRouter() {
  showRoutes(routes);
  const router: Router = _createRouter({
    // history: createMemoryHistory(ROOT)
    // history: createWebHistory(import.meta.env.BASE_URL),
    history: import.meta.env.SSR
      ? createMemoryHistory(ROOT)
      : createWebHistory(ROOT),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
  });
  router.beforeEach((to, from) => {
    const sitelock = useSiteLockStore();
    const user = useUserStore();
    if (!user.isAuthenticated && to.meta.requiresSignin) {
      console.log("signin required");
      return { name: "accounts-signin", query: { path: from.path } };
    }
    if (!user.isRep && to.meta.requiresRep) {
      console.log("rep signin required");
      return { name: "accounts-profile" };
    }
    // if (!sitelock.active) {
    //   const isTester = user.isTester;
    //   if (!isTester && to.name !== "accounts-signin") {
    //     console.log("forcing back to signin", JSON.stringify(user.props));
    //     if (to.name !== "accounts-signin") {
    //       return { name: "accounts-signin", query: { next: to.path } };
    //     }
    //     return { name: "accounts-signin" };
    //   }
    // }
  });
  return router;
}

function showRoutes(routes: any[], base = "") {
  for (const route of routes) {
    // route.props = true;
    log("-", base + route.path, route.name);
    if (route.children) {
      showRoutes(route.children, route.path + "/");
    }
    route.props = true;
  }
}

import api from "@virgodev/bazaar/functions/api";
import copy from "@virgodev/bazaar/functions/copy";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";
import { useCartStore } from "./cart";
import type { Promotion } from "./defs/shop_defs";
import { useShopStore } from "./shop";
import { useUserStore } from "./user";

export const usePromosStore = defineStore("promos", () => {
  const user = useUserStore();
  const cart = useCartStore();
  const shop = useShopStore();
  const storage = useLocalStorageStore();

  const applicableVolume: Ref<{ [key: string]: number }> = ref({});
  const couponPromo: Ref<Promotion | null> = ref(null);

  const qualified = computed(() => {
    if (
      !user.props.rep_number &&
      user.props.groups.indexOf("wholesale") == -1
    ) {
      if (user.props.groups.indexOf("retail") == -1) {
        user.props.groups.push("retail");
      }
    }
    shop.promos.forEach((promo) => {
      applicableVolume.value[promo.id] = getApplicableVolume(promo);
    });

    return shop.promos.filter((promo) => {
      return isPromoValid(promo);
    });
  });

  function getApplicableVolume(promo: Promotion) {
    let volume = 0;
    let limited_to = null;
    if (promo.limit_categories && promo.limit_categories.length > 0) {
      limited_to = promo.limit_categories;
    }

    for (let item of cart.items) {
      if (!item.promotion) {
        let product = shop.products.find((p) => item.product.id === p.id);
        if (
          product &&
          (!limited_to || limited_to.indexOf(product.category) > -1)
        ) {
          volume += product.volume * item.quantity;
        }
      }
    }
    return volume;
  }

  function isPromoValid(promo: Promotion) {
    const groups = user.props.groups || [];
    let ingroup = !promo.group || groups.indexOf(promo.group) > -1;
    let included =
      !promo.exclude_group || groups.indexOf(promo.exclude_group) === -1;
    let max = promo.max_volume;
    let mintotal = null;
    let maxtotal = null;
    if (promo.filters) {
      mintotal = promo.filters.find((f) => f.module == "cart_total");
      maxtotal = promo.filters.find((f) => f.module == "cart_total_max");
    }
    return (
      ((!promo.coupon_code && !promo.has_voucher) ||
        promo.id === couponPromo.value?.id) &&
      applicableVolume.value[promo.id] >= (promo.min_volume || 0) &&
      (!max || max > applicableVolume.value[promo.id]) &&
      ingroup &&
      included &&
      (!mintotal || cart.subtotal >= mintotal.value) &&
      (!maxtotal || cart.subtotal <= maxtotal.value)
    );
  }

  async function applyPromotions() {
    // remove all promo gift items
    const userChoicePromos: number[] = qualified.value
      .filter((q) => q.promo_type === "local_userchoice")
      .map((q) => q.id)
      .filter((q) => q);

    cart.object.items = cart.items.filter((i) => {
      return (
        !i.promotion ||
        userChoicePromos.includes(i.promotion) ||
        i.promotion === couponPromo.value?.id
      );
    });
    cart.object.shippingPromos = undefined;

    const shippingPromos = new Set();
    for (const promo of qualified.value) {
      let sku = null;
      const av = applicableVolume.value[promo.id] || 0;
      if (av >= promo.volume) {
        let valid = true;
        for (let filter of promo.filters || []) {
          if (filter.module === "in_birth_month") {
            valid =
              valid &&
              new Date().getMonth() + 1 === parseInt(user.props.b_month);
          } else if (
            filter.module === "only_once_yearly" ||
            filter.module === "ship_once_yearly" ||
            filter.module === "combined_order" ||
            filter.module === "combined_over"
          ) {
            // break;
            if (filter.id) {
              valid = valid && (await testPromotionFilter(filter.id));
            } else {
              valid = false;
              break;
            }
          }
        }

        if (valid) {
          if (promo.promo_type === "local_gift") {
            sku = promo.limit_freebies_to.toLowerCase();
          } else if (
            promo.promo_type === "local_indexed_gift" ||
            promo.promo_type === "local_batched"
          ) {
            const index = Math.floor(av / promo.volume);
            sku =
              promo.limit_freebies_to.toLowerCase() +
              index.toString().padStart(2, "0");
          }
          if (promo.shipper) {
            shippingPromos.add(promo.id);
          }
        }
      }

      if (sku) {
        const product = shop.objects.products.find(
          (p) => p.remote_id.toLowerCase() === sku,
        );

        // is this already in the cart?
        let cartitem = null;
        if (product) {
          cartitem = cart.items.find((item) => {
            return (
              item.product.id === product.id && item.promotion === promo.id
            );
          });
        }

        let quantity = 1 - (cartitem?.quantity || 0);
        if (promo.promo_type === "local_gift" && promo.cumulative) {
          quantity = (av - (promo.cumulative_offset || 0)) / promo.volume;
          if (promo.cumulative_max) {
            quantity = Math.min(quantity, promo.cumulative_max);
          }
        }

        if (product && quantity !== 0 && !product.extras?.eventforms) {
          console.info("adding sku", sku, quantity, product, promo);

          const promoItem = shop.objects.products.find(
            (p) => p.remote_id.toLowerCase() === sku.toLowerCase(),
          );
          if (promoItem) {
            cart.object.items.push({
              // id: -1,
              product: copy(promoItem),
              quantity: quantity,
              promotion: promo.id,
              promotion_name: promo.description,
              extras: null,
            });
          } else {
            console.warn("missing promo item", sku);
          }
        } else {
          console.warn("item missing", sku, product);
        }
      }
    }

    // update shipping promos
    if (shippingPromos.size > 0) {
      cart.object.shippingPromos = Array.from(shippingPromos) as Promotion[];
    }
  }

  async function testPromotionFilter(filter_id: number) {
    if (cart.object.id) {
      const key = `promo-filter-${filter_id}`;
      let response = storage.get(key);
      if (
        !response ||
        new Date(response.created).getTime() <
          Date.now() - 2 * 24 * 60 * 60 * 1000
      ) {
        response = await api({
          url: "promos/filter/",
          json: {
            filter: filter_id,
            cart: cart.object.id,
          },
          method: "POST",
        });
        storage.put(key, response.body);
      }
      if ("valid" in response) {
        return response.valid;
      }
      return false;
    }
    return false;
  }

  return {
    qualified,
    couponPromo,
    applyPromotions,
  };
});

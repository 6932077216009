import { Deferred, debounce } from "@/utils/bouncer";
import type { Address } from "@paparazzi/types/paparazzi.api.serializers";
import api from "@virgodev/bazaar/functions/api";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import { type FieldDef } from "@virgodev/vue-models/components/forms/defs";
import { ApiAdapter } from "@virgodev/vue-models/utils/adapter";
import { defineStore } from "pinia";
import type { Ref } from "vue";
import { computed, ref, watch } from "vue";
import { useUserStore } from "./user";

export interface AddressOptions {
  [index: string]: string[];
  city: string[];
  county: string[];
  state: string[];
  // error: string[]
}

export const useAddressStore = defineStore("address", () => {
  const user = useUserStore();
  const storage = useLocalStorageStore();
  const synced: Ref<number> = ref(storage.get("address-synced", 0) || 0);
  const list: Ref<Address[]> = ref(storage.get("address-list", []) || []);
  const error = ref(null as string | null);
  const userId = ref(user.props.id);
  const adapter = new ApiAdapter<Address>("addresses");
  const apiZipCode = ref("");
  const countyOptions: Ref<AddressOptions> = ref({
    city: [],
    state: [],
    county: [],
  });
  const allowPOBoxes = ref(true);
  const zipCodeWarn = ref(false);
  const validationOptions = ref<Address[] | null>(null);
  let fetchCountyInfoDeferred = new Deferred();

  const addressFields = computed<FieldDef[]>(() => {
    const placeholder = zipCodeWarn.value
      ? " Enter zip code to continue "
      : undefined;
    return [
      { name: "address", label: "Address", placeholder: "Street Address" },
      {
        name: "address2",
        required: false,
        label: false,
        placeholder: "Building, Suite, or Apartment Number",
        help: allowPOBoxes.value
          ? undefined
          : "*Please note we can not ship to PO Boxes",
      },
      {
        label: false,
        type: "sub",
        css_classes: "hrow zipnstate",
        fields: [
          { name: "zip_code", label: "Zip Code", maxlength: 5, minlength: 5 },
          {
            name: "state",
            label: "State",
            options: listToOptions(countyOptions.value.state),
            default: listToDefault(countyOptions.value.state),
            placeholder,
            focus: () => (zipCodeWarn.value = true),
          },
        ],
      },
      {
        name: "city",
        label: "City",
        options: listToOptions(countyOptions.value.city),
        default: listToDefault(countyOptions.value.city),
        placeholder,
        focus: () => (zipCodeWarn.value = true),
      },
      {
        name: "county",
        label: "County",
        options: listToOptions(countyOptions.value.county),
        default: listToDefault(countyOptions.value.county),
        placeholder,
        focus: () => (zipCodeWarn.value = true),
      },
    ] as FieldDef[];
  });

  const defaultBilling: Ref<Address | undefined> = computed(
    getDefaultAddress("default_billing"),
  );
  const defaultShipping: Ref<Address | undefined> = computed(
    getDefaultAddress("default_shipping"),
  );

  watch(
    list,
    (a, b) => {
      if (list.value.length === 1) {
        list.value[0].default_billing = true;
        list.value[0].default_shipping = true;
      }
    },
    { deep: true },
  );

  watch(
    () => user.props.id,
    () => verifyUserAddresses(),
  );
  verifyUserAddresses();

  // TODO: update cart address?
  // if (this.state.shop.cart) {
  //   for (const key in prev) {
  //     const prevAddress = prev[key];
  //     if (address[`default_${key}`] && (!prevAddress || prevAddress.id !== address.id)) {
  //       const currentAddress = this.state.shop.cart[`${key}_address`];
  //       if (!prevAddress || !currentAddress || currentAddress.id === prevAddress.id) {
  //         if (key === 'billing') {
  //           this.commit('cartBillingAddress', copy(address));
  //         } else {
  //           this.commit('cartShippingAddress', copy(address));
  //         }
  //       }
  //     }
  //   }
  // }

  async function verifyUserAddresses() {
    const pending = list.value.filter((a) => a.id === -1);

    // changed user, clear list
    if ((userId.value || user.props.id) && userId.value !== user.props.id) {
      console.log("clear, changing user");
      list.value = [];
      await pull();
    }

    // save user id for future reference
    userId.value = user.props.id;

    // save temp addresses
    if (pending.length > 0 && user.props.id && user.isAuthenticated) {
      const results = await Promise.all(pending.map(save));
    } else {
      list.value = list.value.concat(pending);
    }
  }

  function getDefaultAddress(label: keyof Address): () => Address | undefined {
    return () => {
      let output: Address | undefined = list.value.find((item) => item[label]);
      if (!output && list.value.length > 0) {
        output = list.value[0];
      }
      return output;
    };
  }

  function isValid(address: Partial<Address>): boolean {
    return (
      !!address.address &&
      !!address.zip_code &&
      address.zip_code.length >= 5 &&
      !!address.state &&
      !!address.city &&
      !!address.county
    );
  }

  async function validate(address: Address) {
    let response = await api({
      url: "addresses/validator/",
      json: address,
      method: "POST",
    });
    if (
      response.ok &&
      response.body.status == "SUCCESS" &&
      response.body.suggestions.length > 0
    ) {
      validationOptions.value = response.body.suggestions;
      if (validationOptions.value) {
        for (const suggestion of validationOptions.value) {
          suggestion.validated = true;
        }
      }
    }
  }

  async function pull(force: boolean = false) {
    if (force || synced.value + 1000 * 60 * 5 < Date.now()) {
      if (user.isAuthenticated && (await debounce("sync-addresses"))) {
        list.value = (await adapter.list({})) as Address[];
        synced.value = Date.now();
        storage.put("address-synced", synced.value);
        storage.put("address-list", list.value);
      }
    }
  }

  async function save(address: Address): Promise<number> {
    if (user.isAuthenticated) {
      address.name = address.name || "default"; // name required
      let response = null;
      let ex = null;
      try {
        const id = (address.id ?? 0) > 0 ? address.id ?? 0 : 0;
        response = await adapter.update(id, address);
      } catch (error) {
        ex = error;
      }
      if (!response) {
        throw new Error(`no response: ${JSON.stringify(ex)}`);
      }
      const id = response.id;
      const existing = list.value.findIndex((a) => a.id === id);
      if (existing > -1) {
        for (const prop in address) {
          list.value[existing][prop as keyof Address] =
            address[prop as keyof Address];
        }
      } else {
        address.id = id as number;
        list.value.push(address);
      }

      // update other addresses to remove old defaults
      let changed = [] as Address[];
      if (address.default_billing) {
        const defaultBilling = list.value.filter(
          (a) => a.id !== address.id && a.default_billing,
        );
        defaultBilling.forEach((a) => (a.default_billing = false));
        changed = [...changed, ...defaultBilling];
      }
      if (address.default_shipping) {
        const defaultShipping = list.value.filter(
          (a) => a.id !== address.id && a.default_shipping,
        );
        defaultShipping.forEach((a) => (a.default_shipping = false));
        changed = [...changed, ...defaultShipping];
      }
      for (const address2 of changed) {
        await adapter.update(address2.id as number, address2);
      }
    } else {
      address.id = -1;
      list.value = [address];
    }
    storage.put("address-list", list.value);
    return address.id ?? 0;
  }

  async function remove(address: Address) {
    if (address.id) {
      await adapter.delete(address.id);
    }
    const index = list.value.findIndex((item) => item.id === address.id);
    list.value.splice(index, 1);
  }

  async function fetchCountyInfo(
    zipcode: string,
    opts?: { existing?: { city?: string; state?: string; county?: string } },
  ): Promise<void> {
    if (opts?.existing) {
      if (opts.existing.city) {
        countyOptions.value.city = [opts.existing.city];
      }
      if (opts.existing.state) {
        countyOptions.value.state = [opts.existing.state];
      }
      if (opts.existing.county) {
        countyOptions.value.county = [opts.existing.county];
      }
    }
    if (zipcode && zipcode.length >= 5) {
      if (zipcode !== apiZipCode.value) {
        fetchCountyInfoDeferred = new Deferred();
        apiZipCode.value = zipcode;
        let response: any = null;
        try {
          response = await api({
            url: "zipcode-lookup/",
            json: { zipcode },
            method: "POST",
          });
        } catch (err) {
          error.value = `${err}`;
        }
        if (response && response.ok) {
          if (response.body.error) {
            console.warn("zipcode lookup failed", zipcode, response.body.error);
            throw new Error(response.body.error);
          }
          const retval: AddressOptions = { city: [], county: [], state: [] };
          for (let key of ["city", "state", "county"]) {
            const array = response.body[key];
            retval[key] = Array.from(new Set(array.map((a: any) => a.id)));
          }
          countyOptions.value = retval;
        } else {
          throw new Error("Server failed to respond");
        }
        fetchCountyInfoDeferred.resolve();
      }
    } else {
      reset();
    }
  }

  function listToOptions(array: string[]): { name: string; value: string }[] {
    return array.map((a) => ({ name: a, value: a }));
  }

  function listToDefault(array: string[]): string | undefined {
    if (array.length > 0) {
      return array[0];
    }
    return undefined;
  }

  function reset() {
    zipCodeWarn.value = false;
    apiZipCode.value = "";
    countyOptions.value = {
      city: [],
      state: [],
      county: [],
    };
  }

  function clear() {
    storage.put("address-synced", 0);
    storage.put("address-list", []);
  }

  return {
    synced,
    list,
    defaultBilling,
    defaultShipping,
    allowPOBoxes,
    fields: addressFields,
    fetchCountyInfoDeferred,
    countyOptions,
    zipCodeWarn,
    isValid,
    save,
    pull,
    remove,
    fetchCountyInfo,
    validate,
    reset,
    clear,
  };
});

import { defineStore } from "pinia";
import type { ToastMessageOptions } from "primevue/toast";
import { useToast } from "primevue/usetoast";

export const useToasterStore = defineStore("toaster", () => {
  if (window.app) {
    const toast = useToast();

    function add(msg: ToastMessageOptions) {
      if (!msg.life) {
        msg.life = 2500;
      }
      toast.add(msg);
    }

    return {
      add,
    };
  }
  return {
    add(...opts) {
      console.warn("no toast", opts);
    },
  };
});
